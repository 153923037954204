import React from 'react'
import QueryTable from 'components/common/queryTable/QueryTable'
import UserForm from './UserForm'

function List(): JSX.Element {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '账号',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '姓名',
      dataIndex: 'fd',
      key: 'fd',
    },
    {
      title: 'DD 号',
      dataIndex: 'fd_nubmer',
      key: 'fd_nubmer',
    },
  ]

  return (
    <>
      <p className="text-right mb-2">
        <UserForm />
      </p>
      <QueryTable
        remoteUrl="/admin/users"
        columns={columns}
        antdTableProps={{
          bordered: true,
        }}
      />
    </>
  )
}

export default List